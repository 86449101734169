import React from "react";
import SearchComponent from "../../components/search.component";
import SortComponent from "../../components/sort.component";

const FilterComponent = () => {
    const sortList = [
        {name: 'Task Name: A-Z', key: 'name-true'},
        {name: 'Task Name: Z-A', key: 'name-false'},
        {name: 'Status: A-Z', key: 'status-true'},
        {name: 'Status: Z-A', key: 'status-false'},
        {name: 'Duration: A-Z', key: 'taskDuration-true'},
        {name: 'Duration: Z-A', key: 'taskDuration-false'},
        {name: 'Task Completed: A-Z', key: 'completionDate-true'},
        {name: 'Task Completed: Z-A', key: 'completionDate-false'},
    ]
    const pageLimitList = [
        {name: 'Show 10 / Page', key: 10},
        {name: 'Show 20 / Page', key: 20},
        {name: 'Show 50 / Page', key: 50},
        {name: 'Show 100 / Page', key: 100},
    ]
    return (
        <div className={"md:row col items-center justify-between space-y-3 md:space-y-0"}>
            <SearchComponent/>
            <div className={"hidden md:block"}>
                <div className={"row items-center justify-between md:space-x-3 2k:space-x-10 w-full md:w-auto"}>
                    <SortComponent prefix={'Sort By :'} dataList={sortList} slug={'sort'}/>
                    <SortComponent dataList={pageLimitList} slug={'limit'}/>
                </div>
            </div>
            <div className={"block md:hidden w-full"}>
                <div className={"grid grid-cols-2 gap-2"}>
                    <SortComponent dataList={sortList} slug={'sort'}/>
                    <SortComponent dataList={pageLimitList} slug={'limit'}/>
                </div>
            </div>
        </div>
    )
}

export default FilterComponent
