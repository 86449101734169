import React from "react";
import ArcticLogo from "../../images/svg/Group 1382.svg";
import ArcticMiniLogo from "../../images/svg/arcticgrey-mini-logo.svg"
import User from "../../images/svg/user.svg"
import {useSelector} from "react-redux";
import {defineHoursRemainingCardColor} from "../../utils/general-variable";
import {Link} from "react-router-dom";

const HeaderComponent = () => {
    const {hoursRemaining} = useSelector(state => state.emailReducer.totalData)
    return (
        <div
            className={"bg-white h-[60px] 2k:h-[90px] border fixed w-full shadow-sm row items-center justify-between md:pl-7 md:pr-3 p-3 z-10"}>
            <div className={"row items-center space-x-5"}>
                <a href={"https://www.arcticgrey.com/"} target={'_blank'}>
                    <img src={ArcticLogo} className={"h-5 2k:h-8 hidden md:block"}/>
                    <img src={ArcticMiniLogo} className={"h-7 block md:hidden"}/>
                </a>
                <div className={"bg-black text-white px-3 py-1 rounded-full"}>
                    Beta
                </div>
            </div>
            <div className={"row items-center space-x-5"}>
                <Link to={'https://arcticgrey.com/account/login'} target="_blank">
                    <img src={User} className={"w-4 2k:w-6"}/>
                </Link>
                <Link to={'https://arcticgrey.com/products/bulk-hours'} target="_blank">
                    <button
                        className={`${hoursRemaining > 599 ? defineHoursRemainingCardColor(hoursRemaining) : 'bg-christmas-green text-white'} h-7 2k:h-10 w-36 2k:w-64 2k:text-2xl rounded shadow-lg`}>Reload
                        Bulk Hours
                    </button>
                </Link>
            </div>
        </div>
    )
}

export default HeaderComponent
