import React from "react";
import PaginationComponent from "./pagination.component";
import TableHeader from "../pages/public-email/table-header";

const TableComponent = (props) => {
    const {columns, dataSource, pagination} = props

    return (
        <>
        <table className={"table-auto md:text-xs 2k:text-2xl text-[9px] w-full bg-white rounded-md font-Poppins-Regular ring-1 ring-gray-100"}>
            <thead>
            <tr>
                {
                    columns.map((item, index) => (
                        <th key={index}>
                            <TableHeader item={item} index={index}/>
                        </th>
                    ))
                }
            </tr>
            </thead>
            <tbody className={"pl-4"}>
            {
                dataSource.map((item, index) => (
                    <tr key={index.toString()} className={"border-y border-gray-100"}>
                        {
                            columns.map((columnItem, idx) => {
                                if (!!columnItem.render) {
                                    return <td className={"md:text-xs 2k:text-xl text-[9px] 2k:h-20"}
                                        key={idx.toString().concat(index)}>{columnItem.render(item[columnItem.dataIndex], item)}</td>
                                } else {
                                    return <td key={idx.toString().concat(index)}
                                               className={`md:text-xs 2k:text-xl text-[9px] text-dark ${columnItem.dataIndex === 'name' && 'md:w-6/12 w-40'} h-12 ${idx === 0 && 'pl-4'} ${columnItem.bodyClassName}`}>{item[columnItem.dataIndex]}</td>
                                }

                            })
                        }
                    </tr>
                ))
            }
            </tbody>
        </table>
            {!!pagination && <PaginationComponent slug={'christmas'} />}
        </>
    )
}

export default TableComponent
