import {createSlice} from '@reduxjs/toolkit'

export const adminSlice = createSlice({
    name: 'admin',
    initialState: {
        editUserModal: false,
        userDetailToEdit: {
            full_name: '',
            email: '',
            title: '',
            permission: []
        },
        deleteUserModal: false
    },
    reducers: {
        setAdminEditUserModal: (state, action) => {
            state.editUserModal = action.payload
        },
        setAdminUserDetailToEdit: (state, action) => {
            state.userDetailToEdit = action.payload
        },
        setAdminDeleteUserModal: (state, action) => {
            state.deleteUserModal = action.payload
        },
    },
})

export const {setAdminEditUserModal, setAdminUserDetailToEdit, setAdminDeleteUserModal} = adminSlice.actions
const adminReducer = adminSlice.reducer

export default adminReducer
