import React, {useEffect} from "react";
import FilterDateComponent from "./filter-date.component";
import TotalCardComponent from "./total-card.component";
import TaskListComponent from "./task-list.component";
import FooterComponent from "../../components/footer.component";
import FilterComponent from "./filter.component";
import {getTaskByBoardIdApi, getTitleByBoardIdApi} from "../../api/task.api";
import {useDispatch, useSelector} from "react-redux";
import {setEmailLoading, setEmailProjectTitle, setEmailTaskList, setEmailTotalData} from "../../reducers/email.reducer";
import {formatDateToReadable} from "../../utils/general-variable";
import {setToolsDateRange, setToolsTotalData, setToolsTotalPage} from "../../reducers/tools.reducer";
import LoadingSvg from "../../images/svg/Ellipsis-2.7s-200px.svg"
import {useParams} from "react-router-dom";

const PublicEmailPages = () => {
    const {id, start, end} = useParams();
    const dispatch = useDispatch()
    const {toolsReducer, emailReducer} = useSelector(state => state)
    const {
        currentPage,
        pageSize,
        sortBy,
        sortType,
        searchKey,
        dateRange
    } = toolsReducer
    const {loading} = emailReducer

    useEffect(() => {
        getTitleByBoardIdApi(id)
            .then(response => {
                dispatch(setEmailProjectTitle(response.name))
            })
            .catch(err => {
                console.log(err)
            })
    }, [])

    useEffect(() => {
        if(start){
            dispatch(setToolsDateRange([start, end]))
        } else if(!!start && !!dateRange[0]){
            getDataList()
        }
    }, [])

    useEffect(() => {
        getDataList()
    }, [dateRange, searchKey, currentPage, pageSize, sortType, sortBy])

    const getDataList = () => {
        let params = {
            startDate: formatDateToReadable(!!dateRange[0] ? dateRange[0] : start, 'YYYY-MM-DD'),
            endDate: formatDateToReadable(!!dateRange[1] ? dateRange[1] : end, 'YYYY-MM-DD'),
            search: searchKey,
            sortBy,
            sortType: sortType ? 'asc' : 'desc',
            page: currentPage,
            limit: pageSize
        }

        dispatch(setEmailLoading(true))

        getTaskByBoardIdApi(id, params)
            .then(response => {
                dispatch(setEmailTaskList(response.rows))
                dispatch(setEmailTotalData(response.totalStats))
                dispatch(setToolsTotalPage(response.totalPage))
                dispatch(setToolsTotalData(response.totalResult))
                dispatch(setEmailLoading(false))
            })
            .catch(err => {
                console.log('Error getTaskByBoardIdApi',err)
                dispatch(setEmailTaskList([]))
                dispatch(setEmailTotalData({
                    bulkHoursRemaining: 0,
                    hoursLogged: 0,
                    totalCompleted: 0,
                    totalInProgress: 0
                }))
                dispatch(setToolsTotalPage(1))
                dispatch(setToolsTotalData(0))
                dispatch(setEmailLoading(false))
            })
    }

    return (
        <div className={"col w-full font-Poppins-Regular h-full justify-between relative"}>
            <div className={"lg:space-y-10 space-y-5 lg:px-40 px-3 py-10 flex-1"}>
                <FilterDateComponent/>
                {
                    loading ?
                        <div className={"col items-center justify-center h-52"}>
                            <img src={LoadingSvg} className={"h-20"}/>
                        </div> : null
                }
                        <div className={`${loading ? 'hidden' : 'block'} lg:space-y-10 space-y-5`}>
                            <TotalCardComponent/>
                            <FilterComponent/>
                            <TaskListComponent/>
                        </div>
            </div>
            <FooterComponent/>
        </div>
    )
}

export default PublicEmailPages
